import React, { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ChevronDown, CheckCircle, BarChart2, FileText, Users, Sliders, Layers, Ruler, Map, TrendingDown, DollarSign, Eye, Zap } from 'lucide-react';
import logoImage from './logo.png';


const LandingPage = () => {
  const canvasRef = useRef(null);
  const [formStatus, setFormStatus] = useState('');

  useEffect(() => {
    // Three.js scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);

    // Create a sphere geometry
    const geometry = new THREE.SphereGeometry(1, 32, 32);
    const material = new THREE.MeshBasicMaterial({ color: 0x00a86b, wireframe: true });
    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    camera.position.z = 3;

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = false;

    const animate = () => {
      requestAnimationFrame(animate);
      sphere.rotation.x += 0.005;
      sphere.rotation.y += 0.005;
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    try {
      const response = await fetch('https://formspree.io/f/mnnaoqoq', {
        method: 'POST',
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.ok) {
        setFormStatus('success');
        form.reset();
      } else {
        setFormStatus('error');
      }
    } catch (error) {
      setFormStatus('error');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-500 to-teal-700 text-white">
      <canvas ref={canvasRef} className="absolute inset-0 z-0" />
      <div className="relative z-10">
        <header className="container mx-auto px-4 py-6">
          <nav className="flex justify-between items-center">
            <div className="text-2xl font-bold">PGProject</div>
            <ul className="flex space-x-4">
              <li><a href="#features" className="hover:text-teal-200">Recursos</a></li>
              <li><a href="#benefits" className="hover:text-teal-200">Benefícios</a></li>
              <li><a href="#about" className="hover:text-teal-200">Sobre</a></li>
              <li><a href="#contact" className="hover:text-teal-200">Contato</a></li>
            </ul>
          </nav>
        </header>

        <main>
          <section className="hero container mx-auto px-4 py-20 text-center">
            <h1 className="text-5xl font-bold mb-4">Plataforma de Gestão de Projetos</h1>
            <p className="text-xl mb-8">Simplificando a gestão de projetos complexos de infraestrutura</p>
            <a href="#" className="bg-white text-teal-700 px-6 py-3 rounded-full font-semibold hover:bg-teal-100 transition duration-300">
              Comece Agora
            </a>
          </section>

          <section id="features" className="container mx-auto px-4 py-20">
            <h2 className="text-3xl font-bold mb-12 text-center">Recursos Principais</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <FeatureCard
                icon={<BarChart2 size={40} />}
                title="Gerenciamento de Projetos"
                description="Defina metas, crie cronogramas e acompanhe o progresso de forma visual e intuitiva."
              />
              <FeatureCard
                icon={<FileText size={40} />}
                title="Controle Financeiro"
                description="Gerencie orçamentos, custos e gere relatórios financeiros completos."
              />
              <FeatureCard
                icon={<Users size={40} />}
                title="Gerenciamento de Contratos"
                description="Controle datas, valores e cláusulas contratuais de forma eficiente."
              />
              <FeatureCard
                icon={<Sliders size={40} />}
                title="Licitações Sob Controle"
                description="Gerencie editais, propostas e resultados de licitações com facilidade."
              />
              <FeatureCard
                icon={<Layers size={40} />}
                title="Gestão Flexível de Programas"
                description="Gerencie múltiplos planos e programas de forma autônoma com nosso app dedicado de programas."
                />
                <FeatureCard
                icon={<Ruler size={40} />}
                title="Controle de Medições e Serviços"
                description="Acompanhe e gerencie medições e serviços com precisão, garantindo o controle efetivo do progresso de intervenções."
                />
            </div>
          </section>

          <section id="benefits" className="bg-teal-600 py-20">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold mb-12 text-center">Benefícios Incomparáveis</h2>
              <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <BenefitItem icon={<Map size={20} />} text="Mapa interativo de obras" />
                <BenefitItem icon={<BarChart2 size={20} />} text="Controle preciso para tomada de decisões" />
                <BenefitItem icon={<TrendingDown size={20} />} text="Economia com redução de erros e desperdícios" />
                <BenefitItem icon={<DollarSign size={20} />} text="Gestão de fontes e financiamentos" />
                <BenefitItem icon={<Eye size={20} />} text="Transparência no acompanhamento de obras" />
                <BenefitItem icon={<Zap size={20} />} text="Eficiência na automação de tarefas" />
              </ul>
            </div>
          </section>

          <section id="about" className="container mx-auto px-4 py-20">
            <h2 className="text-3xl font-bold mb-8 text-center">Sobre o PGProject</h2>
            <p className="text-lg mb-6">
              O PGProject é uma plataforma web robusta e completa, desenvolvida para auxiliar empresas e órgãos públicos
              a alcançar o sucesso em seus empreendimentos de infraestrutura. Nossa solução oferece funcionalidades
              abrangentes que simplificam processos, otimizam recursos e garantem a transparência em todas as fases do projeto.
            </p>
            <p className="text-lg">
              Desenvolvido com tecnologia de ponta, utilizando as melhores práticas de segurança e escalabilidade,
              o PGProject está pronto para atender às demandas mais complexas de gerenciamento de projetos.
            </p>
          </section>

          <section id="contact" className="bg-teal-800 py-20">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold mb-8 text-center">Entre em Contato</h2>
                <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
                <div className="mb-4">
                    <input type="text" name="name" placeholder="Nome" required className="w-full px-4 py-2 rounded bg-teal-700 text-white placeholder-teal-300" />
                </div>
                <div className="mb-4">
                    <input type="email" name="email" placeholder="E-mail" required className="w-full px-4 py-2 rounded bg-teal-700 text-white placeholder-teal-300" />
                </div>
                <div className="mb-4">
                    <textarea name="message" placeholder="Mensagem" rows="4" required className="w-full px-4 py-2 rounded bg-teal-700 text-white placeholder-teal-300"></textarea>
                </div>
                <button type="submit" className="w-full bg-white text-teal-800 px-6 py-3 rounded font-semibold hover:bg-teal-100 transition duration-300">
                    Enviar Mensagem
                </button>
                {formStatus === 'success' && (
                    <p className="mt-4 text-green-300">Mensagem enviada com sucesso!</p>
                )}
                {formStatus === 'error' && (
                    <p className="mt-4 text-red-300">Ocorreu um erro. Por favor, tente novamente.</p>
                )}
                </form>
            </div>
            </section>
        </main>

        <footer className="bg-teal-900 py-6">
          <div className="container mx-auto px-4 text-center">
            <p>&copy; 2024 PGProject. Todos os direitos reservados.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-teal-600 p-6 rounded-lg shadow-lg">
    <div className="mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

const BenefitItem = ({ text }) => (
  <li className="flex items-center space-x-2">
    <CheckCircle size={20} />
    <span>{text}</span>
  </li>
);

export default LandingPage;